<template>
  <div id="table" class="relative">
    <div class="flex justify-end">
      <Pagination
        v-if="pagination"
        :currentPage="pagination.currentPage"
        :perPage="pagination.perPage"
        :from="pagination.from"
        :to="pagination.to"
        :lastPage="pagination.lastPage"
        :total="pagination.total"
        @paginateTo="$emit('paginateTo', $event)"
      />
    </div>
    <div class="empty-state w-full" v-if="showEmptyState">
      <figure class="max-w-lg relative left-1/2 transform -translate-x-1/2">
        <img
          class="w-full h-full object-cover"
          src="@/assets/img/empty-states/no-change-requests.svg"
          alt="Empty State"
        />
      </figure>
      <h3
        class="font-sans text-black-base text-xl font-semibold text-center mt-6"
      >
        No requests yet
      </h3>
      <p class="font-sans text-black-lighter text-center">
        There are no requests under this section yet.
      </p>
    </div>

    <div v-else>
      <div class="t-header">
        <div
          v-for="(title, index) in header"
          :key="index"
          :class="[
            'text-xs uppercase font-sans text-black-lighter',
            index == 5 ? 'col-span-1' : 'col-span-2',
          ]"
        >
          {{ title }}
        </div>
      </div>

      <RequestTableRow
        class="mb-2"
        v-for="(request, index) in requests"
        :key="index"
        :request="request"
        :showStatus="true"
        :showTotal="showTotal"
      />
    </div>

    <div
      class="flex justify-end"
      v-if="requests.length > pagination.perPage / 2"
    >
      <Pagination
        v-if="pagination"
        :currentPage="pagination.currentPage"
        :perPage="pagination.perPage"
        :from="pagination.from"
        :to="pagination.to"
        :lastPage="pagination.lastPage"
        :total="pagination.total"
        @paginateTo="$emit('paginateTo', $event)"
      />
    </div>
  </div>
</template>
<script>
import RequestTableRow from "./RequestTableRow";
import Pagination from "@/components/Pagination";

export default {
  name: "Table",
  components: {
    RequestTableRow,
    Pagination,
  },
  props: {
    header: {
      type: Array,
      default: () => [
        "Title 1",
        "Title 2",
        "Title 3",
        "Title 4",
        "Title 5",
        "Title 6",
      ],
    },
    requests: {
      type: Array,
      default: () => [],
    },

    pagination: {
      type: Object,
      required: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showEmptyState() {
      let boolean = this.requests.length > 0;
      return !boolean;
    },
  },
};
</script>

<style>
.table-column {
  @apply text-sm font-sans text-black-base font-semibold flex items-center col-span-2;
}

.t-header {
  @apply hidden sm:grid px-6 grid-flow-col grid-cols-12 gap-4 mt-8 pb-3 w-full;
}
</style>
