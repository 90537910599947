<template>
  <div class="pagination" v-if="lastPage>1">
    <nav aria-label="Page navigation mt-12">
      <div class="inline-flex border border-teal-base rounded">
        <button class="px-3 flex items-center justify-center text-teal-base" :class="{'text-grey-dark':!previousPage}"
                @click="$emit('paginateTo',previousPage)"
                :disabled="!previousPage">Previous
        </button>
        <button v-for="pageNum in pagesToShow" :key="pageNum+'_pagination'" @click="$emit('paginateTo',pageNum)"
                class=" py-2 px-3 w-12 flex items-center justify-center border-l border-teal-base block"
                :class="[pageNum == currentPage? 'text-white bg-teal-base':'text-teal-base'  ]">
          {{ pageNum }}
        </button>
        <button
            class="border-l border-teal-base  block py-2 px-3 text-center flex items-center justify-center text-teal-base"
            @click="$emit('paginateTo',nextPage)" :disabled="!nextPage" :class="{'text-grey-dark':!nextPage}">Next
        </button>
      </div>
    </nav>

  </div>
</template>ß

<script>
export default {
  name: "Pagination.vue",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    lastPage: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    from: {
      type: Number,
      required: true
    },
    to: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    pagesToShow() {
      let numShown = 5;   // This sets the number of page tabs
      numShown = Math.min(numShown, this.lastPage);
      let first = this.currentPage - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.lastPage - numShown + 1);
      return [...Array(numShown)].map((k, i) => i + first);
    },
    previousPage() {
      if (this.currentPage >1) {
        return this.currentPage - 1
      }
      return null;
    },

    nextPage() {
      if (this.currentPage < this.lastPage) {
        return this.currentPage + 1
      }
      return null;
    }
  }

}
</script>
