<template>
  <div class="dialog">
    <ButtonClose class="ml-auto block" @click.native="$emit('closeDialog')"/>
    <h4 class="dialog-title">Assign this request to:</h4>
    <div class="flex flex-col">
      <SelectSearch :show-label="false"
                    :resultItems="partnersList"
                    placeholder="Search a partner"
                    emptyStateTitle="No partner found"
                    emptyStateText="No partners could be found under the given keyword. Try searching again or add a new partner to the Travel Afrique database."
                    primaryAction="Add new partner"
                    v-model="selectedPartner"
      />

      <InputText
          :label="`Estimated budget for partner in (${this.selectedTripRequest.tripCurrency})`"
          placeholder="1000"
          type="text"
          class="my-6"
          name="estimated-budget"
          v-model="travelafriqueBudgetForPartner"
      />


      <ButtonPrimary text="Assign to partner" @click.native="assignToPartner"/>

    </div>

    <div v-if="selectedPartner.name" class="mt-2">
      <p class="text-xs text-teal-500">{{ summary }}</p>
    </div>

    <div class="relative my-8">
      <hr class=" border-t border-teal-base"/>
      <p class="uppercase text-center text-teal-base text-xs  bg-white absolute top-0 absolute-center w-12">or</p>
    </div>

    <div>
      <ButtonSecondary class="w-full" text="Assign to me" @click.native="assignToPartner('self')"/>
    </div>
  </div>
</template>
<script>
import ButtonSecondary from "@/components/Buttons/ButtonSecondary";
//import InputText from "@/components/Inputs/InputText";
import ButtonClose from "@/components/Buttons/ButtonClose";
import SelectSearch from "@/components/Inputs/SelectSearch/SelectSearch";
import {mapActions, mapGetters} from "vuex";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import InputText from "../Inputs/InputText";

export default {
  name: "AssignmentDialog",
  components: {
    InputText,
    ButtonPrimary,
    SelectSearch,
    ButtonClose,
    ButtonSecondary,
    //InputText,
  },

  data() {
    return {
      selectedPartner: {},
      travelafriqueBudgetForPartner: ""
    }
  },
  props: {
    title: {
      type: String,
      default: "Dialog title",
    },
    text: {
      type: String,
      default: "Are you sure you want to do this action?",
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
    faIconClass: {
      type: String,
      default: "fas fa-exclamation-circle",
    },
    iconColor: {
      type: String,
      default: "text-grey-dark",
    },
  },
  mounted() {
    this.getPartners();
  },

  methods: {
    ...mapActions({
      getPartners: 'partners/getPartners',
      hideAssignmentDialog: 'requests/hideAssignmentDialog'
    }),
    assignToPartner(type = "partner") {
      //show another modal for confirmation

      let partnerID = this.selectedPartner.id;
      let summary = this.summary;

      if (type === 'self') {
        partnerID = 24;
        summary = `This will assign the trip request (${this.selectedTripRequest.id}) to
            us Travel Afrique Admins`
      }

      let data = {
        tripRequestId: this.selectedTripRequest.id,
        summary: summary,
        taBudget: this.travelafriqueBudgetForPartner,
        partnerId: partnerID
      }
      this.hideAssignmentDialog();
      this.$emit('assignToPartner', data)
    }


  },
  computed: {
    ...mapGetters({
      approvedPartners: 'partners/approvedPartners',
      selectedTripRequest: 'requests/selectedTripRequest'
    }),

    summary() {

      if (this.selectedPartner.name) {
        return `This will assign the trip request (${this.selectedTripRequest.id}) to
        ${this.selectedTripRequest.tripDestination} by ${this.selectedTripRequest.tripFirstname}
        ${this.selectedTripRequest.tripLastname} to
        ${this.selectedPartner.name}.`
      } else {
        return ""
      }


    },

    partnersList() {
      let partners = []
      this.approvedPartners.forEach(p => {
        partners.push({
          id: p.id,
          name: p.companyName,
          details: p.email,
          showDetails: false,
          showIcon: true,
          faIconClass: "bxs-user",
          showHotelStars: false,
          numberOfStars: "0",
        })
      })

      return partners
    }
  }
};
</script>
<style scoped>
.dialog {
  @apply bg-white rounded-md max-w-md p-6 py-8 w-full shadow;
}

.dialog-title {
  @apply font-sans text-base font-semibold text-black-base mb-2;
}

.dialog-input-btn-container {
  @apply grid grid-cols-12 gap-3 grid-flow-col;
}

.dialog-input-btn-container > div {
  @apply col-span-9 h-8;
}

.dialog-input-btn-container label .input-field {
  @apply p-2.5;
}

.dialog-input-btn-container button {
  @apply col-span-9;
}
</style>