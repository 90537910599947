<template>
  <div @click="openRequest(request.id)" class="t-row relative">
    <div class="row-mb">
      <div class="w-full relative">
        <div class="row-mb-header">
          <timeago
            class="row-mb-date"
            :datetime="request.updatedAt"
            :auto-update="60"
          >
          </timeago>
          <div v-if="showStatus" class="status-container-mb">
            <span :class="statusStyle">
              {{ request.tripRequestStatus }}
            </span>
          </div>
        </div>

        <div class="row-mb-name">{{ name }}</div>
        <div class="row-mb-reference">Reference nr. {{ request.id }}</div>
        <div class="row-mb-text">{{ request.tripDestination }}</div>
        <div class="row-mb-text">{{ budgetPerTraveler }} / traveler</div>
      </div>
    </div>

    <div class="row-text font-semibold">
      {{ request.tripDestination }}
    </div>
    <div class="row-text">
      {{ request.id }}
    </div>
    <div class="row-text">
      {{ name }}
    </div>
    <div class="row-text" v-if="request.baseTripBudget">{{ request.baseTripCurrency }} {{ totalBaseTripBudget }}</div>
    <div class="row-text" v-else  id="old-format">{{ request.tripCurrency }} {{ totalTripBudget }}</div>


    <div class="row-text">
      <timeago :datetime="request.updatedAt" :auto-update="60"></timeago>
    </div>

    <div class="flex items-center gap-2">
      <div v-if="showStatus" class="status-container-dt">
        <span :class="statusStyle">
          {{ request.tripRequestStatus }}
        </span>
      </div>

      <!-- <figure class="avatar" v-if="request.partner">
        <div
          v-if="request.partner && request.partner.profilePhoto"
          class="w-8 h-8"
        >
          <img
            :src="basePath + request.partner.profilePhoto"
            :alt="request.partner.name"
            class="object-cover w-8 h-8 inline"
          />
        </div>

        <div v-else>
          <img :src="getImg.travelafrique" alt="Travel Afrique Logo" />
        </div>
      </figure>
    <div class="btn-tooltip-container">
        <button
          v-show="!request.partner"
          @click.stop="toggleOverlay"
          type="button"
          class="btn-assign"
        >
          <i class="bx bx-user"></i>
        </button>
        <Tooltip
          class="ta-tooltip"
          position="top right"
          text="Assign this request"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
//import Tooltip from "@/components/Tooltip";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TableRow",
  components: {
    //Tooltip,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currency: this.request.tripCurrency,
      region: "en-FR",
    };
  },
  methods: {
    ...mapActions({
      openOverlay: "requests/openOverlay",
      showAssignmentDialog: "requests/showAssignmentDialog",
      setSelectedTripRequest: "requests/setSelectedTripRequest",
    }),
    openRequest(requestId) {
      this.$router.push(`/request/${requestId}`);
    },
    toggleOverlay() {
      //this method is responsible for opening the assignment overlay and setting the selected request
      //set selected request
      this.setSelectedTripRequest(this.request);
      this.openOverlay();
      this.showAssignmentDialog();
    },
    formatToCurrency(number, currency) {
      return new Intl.NumberFormat(this.region, {
        style: "currency",
        currency: currency || this.currency,
      }).format(number);
    },
  },
  computed: {
    ...mapGetters({
      showAvatar: "requests/showAvatar",
      exchangeRates: "offer/exchangeRates",
      getImg: "requests/getImg",
    }),

    selectedCurrencyRates() {
      if (Object.keys(this.exchangeRates).length) {
        return JSON.parse(JSON.stringify(this.exchangeRates[`ratesCHF`]));
      } else {
        return {};
      }
    },

    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    name() {
      return this.request.tripFirstname + " " + this.request.tripLastname;
    },
    statusStyle() {
      let status = this.request.tripRequestStatus;

      if (status === "review") return "status status-review";

      if (status === "pending") return "status status-pending";

      if (status === "open") return "status status-open";

      if (status === "draft") return "status status-draft";

      if (status === "completed") return "status status-completed";

      if (status === "changes") return "status status-changes";

      return "status status-cancelled";
    },
    budgetPerTraveler() {
      return this.formatToCurrency(this.request.tripBudget);
    },
    totalTripBudget() {
      return (
        (this.request.tripAdults + this.request.tripChildren) *
        this.request.tripBudget
      ).toLocaleString();
    },
    totalBaseTripBudget() {
      return (
        (this.request.tripAdults + this.request.tripChildren) *
        this.request.baseTripBudget
      ).toLocaleString();
    },
  },
};
</script>
<style scoped>
.t-row {
  @apply grid grid-flow-col grid-cols-12 gap-4 relative;
  @apply sm:h-14 sm:px-6 w-full mb-4;
  @apply bg-white border border-grey-base;
  @apply transition duration-300 rounded;
  @apply cursor-pointer;
}

.row-mb {
  @apply sm:hidden col-span-12 flex justify-between p-4;
}

.row-mb-header {
  @apply inline-flex items-center;
}

.row-mb-date {
  @apply mr-3 text-xs text-black-lighter;
}

.row-mb-name {
  @apply font-semibold leading-5;
}

.row-mb-reference {
  @apply text-sm text-black-lighter leading-5;
}

.row-mb-text {
  @apply text-sm leading-5;
}

.row-mb-icon-container {
  @apply relative w-7 h-full;
}

.row-text {
  @apply text-sm font-sans text-black-base col-span-2 hidden sm:flex items-center;
}

.status-container-mb {
  @apply ml-auto;
}

.status-container-dt {
  @apply hidden sm:flex items-center;
}

.status-container-mb {
  @apply ml-auto;
}

.status {
  @apply text-xs font-sans inline-block px-2 py-1 rounded-full;
}

.status-review {
  @apply text-blue-text bg-blue-light;
}

.status-pending {
  @apply text-yellow-text bg-yellow-light;
}

.status-open {
  @apply text-teal-text bg-teal-light;
}

.status-completed {
  @apply text-green-text bg-green-light;
}

.status-cancelled {
  @apply text-red-text bg-red-light;
}

.status-changes {
  @apply text-violet-text bg-violet-light;
}

.status-draft {
  @apply text-black-base bg-grey-base;
}

.avatar {
  @apply w-8 h-8 rounded-full border border-grey-dark overflow-hidden relative;
}

.avatar img {
  @apply h-full w-full object-cover;
}

.btn-tooltip-container {
  @apply relative;
}

.btn-assign {
  @apply w-8 h-8 rounded-full border border-black-lighter border-dashed text-black-lightest;
  @apply flex justify-center items-center;
  @apply transition-all ease-in-out duration-300;
  @apply hover:bg-grey-light;
  @apply absolute right-6 md:right-0 top-1/2 transform -translate-y-1/2;
}

.btn-assign + .ta-tooltip {
  @apply fixed right-18 mt-14 z-10 transition-all h-0 overflow-hidden ease-in-out duration-700 opacity-0;
}

.btn-assign:hover + .ta-tooltip {
  @apply inline-block opacity-100 h-auto overflow-visible hidden sm:block;
}
</style>
